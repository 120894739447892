// src/components/Dashboard/Sidebar.tsx
import React, { useState } from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Collapse,
  Box,
  Typography,
  IconButton,
  Divider,
  Tooltip,
  Badge,
} from '@mui/material';
import {
  Dashboard as DashboardIcon,
  Security,
  Analytics,
  Settings,
  Help,
  ExpandLess,
  ExpandMore,
  NetworkCheck,
  Book,
  ErrorOutline,
  Speed,
  Build,
  Star,
  Notifications as NotificationsIcon,
  AccountCircle,
} from '@mui/icons-material';

interface SidebarProps {
    open: boolean;
}

interface MenuItem {
  text: string;
  icon: JSX.Element;
  badge?: number;
  children?: MenuItem[];
}

const Sidebar: React.FC<SidebarProps> = ({ open }) => {
  const drawerWidth = 270;
  const [activeItem, setActiveItem] = useState('Dashboard');
  const [expandedItems, setExpandedItems] = useState<string[]>([]);

  const menuItems: MenuItem[] = [
    { 
      text: 'Dashboard',
      icon: <DashboardIcon />,
    },
    {
      text: 'Security',
      icon: <Security />,
      badge: 3,
      children: [
        { text: 'Active Alerts', icon: <ErrorOutline />, badge: 2 },
        { text: 'Risk Overview', icon: <Analytics /> },
        { text: 'Security Reports', icon: <Security />, badge: 1 },
      ]
    },
    {
      text: 'Network',
      icon: <NetworkCheck />,
      children: [
        { text: 'Protocol Overview', icon: <Speed /> },
        { text: 'Transactions', icon: <Analytics /> },
        { text: 'Connected Protocols', icon: <Book /> },
      ]
    },
    {
      text: 'Reports',
      icon: <Analytics />,
      children: [
        { text: 'Security Insights', icon: <Analytics /> },
        { text: 'Risk Analysis', icon: <Book /> },
        { text: 'Performance', icon: <Speed /> },
      ]
    },
    { 
      text: 'Help & Support',
      icon: <Help />
    },
    { 
      text: 'Settings',
      icon: <Settings />,
      children: [
        { text: 'Notifications', icon: <NotificationsIcon /> },
        { text: 'Account', icon: <AccountCircle /> },
        { text: 'Preferences', icon: <Build /> },
      ]
    },
  ];

  const handleClick = (text: string) => {
    if (expandedItems.includes(text)) {
      setExpandedItems(expandedItems.filter(item => item !== text));
    } else {
      setExpandedItems([...expandedItems, text]);
    }
    setActiveItem(text);
  };

  const renderMenuItem = (item: MenuItem, depth = 0) => {
    const hasChildren = item.children && item.children.length > 0;
    const isExpanded = expandedItems.includes(item.text);
    const isActive = activeItem === item.text;

    return (
      <React.Fragment key={item.text}>
        <ListItem 
          disablePadding
          sx={{ 
            display: 'block',
            pl: depth * 2,
          }}
        >
          <ListItemButton
            selected={isActive}
            onClick={() => handleClick(item.text)}
            sx={{
              minHeight: 48,
              px: 2.5,
              borderRadius: '0 24px 24px 0',
              marginRight: 1,
              '&.Mui-selected': {
                backgroundColor: 'primary.main',
                color: 'primary.contrastText',
                '&:hover': {
                  backgroundColor: 'primary.dark',
                },
                '& .MuiListItemIcon-root': {
                  color: 'primary.contrastText',
                },
              },
            }}
          >
            <ListItemIcon sx={{ minWidth: 40 }}>
              {item.badge ? (
                <Badge badgeContent={item.badge} color="error">
                  {item.icon}
                </Badge>
              ) : item.icon}
            </ListItemIcon>
            <ListItemText 
              primary={item.text}
              sx={{ 
                opacity: open ? 1 : 0,
                transition: 'opacity 0.2s',
              }} 
            />
            {hasChildren && open && (
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  handleClick(item.text);
                }}
              >
                {isExpanded ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            )}
          </ListItemButton>
        </ListItem>
        {hasChildren && item.children && (
          <Collapse in={isExpanded && open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {item.children?.map(child => renderMenuItem(child, depth + 1))}
            </List>
          </Collapse>
        )}
      </React.Fragment>
    );
  };

  return (
    <Drawer
      variant="permanent"
      open={open}
      sx={{
        width: open ? drawerWidth : 64,
        transition: theme => theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        '& .MuiDrawer-paper': {
          width: open ? drawerWidth : 64,
          transition: theme => theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
          overflowX: 'hidden',
          backgroundColor: 'background.paper',
          borderRight: '1px solid',
          borderColor: 'divider',
        },
      }}
    >
      <Box sx={{ mt: 8, overflow: 'hidden', display: 'flex', flexDirection: 'column', height: '100%' }}>
        {/* Quick Stats */}
        {open && (
          <Box sx={{ p: 2 }}>
            <Typography variant="subtitle2" color="text.secondary">
              System Status
            </Typography>
            <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
              <Tooltip title="System Healthy">
                <Star color="success" fontSize="small" />
              </Tooltip>
              <Typography variant="body2">
                All Systems Operational
              </Typography>
            </Box>
          </Box>
        )}
        <Divider />
        <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
          <List>
            {menuItems.map(item => renderMenuItem(item))}
          </List>
        </Box>
        {/* Footer */}
        {open && (
          <Box sx={{ 
            p: 2, 
            borderTop: 1, 
            borderColor: 'divider',
            backgroundColor: 'background.paper',
          }}>
            <Typography variant="caption" color="text.secondary">
              SEKURTY AI v1.0.0
            </Typography>
          </Box>
        )}
      </Box>
    </Drawer>
  );
};

export default Sidebar;