import React from 'react';
import {
  Paper,
  Grid,
  Typography,
  Box,
} from '@mui/material';
import {
  TrendingUp,
  Security,
  Speed,
  Psychology,
} from '@mui/icons-material';

const MetricCard: React.FC<{
  title: string;
  value: string;
  subtitle: string;
  icon: React.ReactNode;
  color: string;
}> = ({ title, value, subtitle, icon, color }) => (
  <Box sx={{ p: 2 }}>
    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
      <Box sx={{ 
        backgroundColor: `${color}20`, 
        borderRadius: '50%', 
        p: 1, 
        mr: 2 
      }}>
        {icon}
      </Box>
      <Box>
        <Typography variant="subtitle2" color="textSecondary">
          {title}
        </Typography>
        <Typography variant="h4" sx={{ my: 1 }}>
          {value}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {subtitle}
        </Typography>
      </Box>
    </Box>
  </Box>
);

const AIMetrics: React.FC = () => (
  <Paper sx={{ p: 2 }}>
    <Grid container spacing={3}>
      <Grid item xs={12} md={3}>
        <MetricCard
          title="AI Detection Rate"
          value="99.8%"
          subtitle="Last 24 hours"
          icon={<TrendingUp sx={{ color: '#4CAF50' }} />}
          color="#4CAF50"
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <MetricCard
          title="Threat Prevention"
          value="47"
          subtitle="Threats blocked today"
          icon={<Security sx={{ color: '#2196F3' }} />}
          color="#2196F3"
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <MetricCard
          title="Response Time"
          value="0.3s"
          subtitle="Average detection time"
          icon={<Speed sx={{ color: '#FF9800' }} />}
          color="#FF9800"
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <MetricCard
          title="AI Confidence"
          value="96.5%"
          subtitle="Model accuracy"
          icon={<Psychology sx={{ color: '#9C27B0' }} />}
          color="#9C27B0"
        />
      </Grid>
    </Grid>
  </Paper>
);

export default AIMetrics;