// src/components/AlertsPanel/index.tsx
import React, { useState, useEffect } from 'react';
import {
  Paper,
  Typography,
  Box,
  Alert,
  AlertTitle,
  IconButton,
  Chip,
  Button,
  Stack,
} from '@mui/material';
import {
  Error as ErrorIcon,
  Warning as WarningIcon,
  Info as InfoIcon,
  Close as CloseIcon,
} from '@mui/icons-material';

interface AlertItem {
  id: number;
  severity: 'error' | 'warning' | 'info';
  title: string;
  message: string;
  timestamp: string;
  protocol: string;
  impact: 'High' | 'Medium' | 'Low';
  aiConfidence: number;
}

// Chain-specific initial alerts
const chainAlerts: { [key: string]: AlertItem[] } = {
  xrpl: [
    {
      id: 1,
      severity: 'error',
      title: 'XRPL Node Alert',
      message: 'Unusual validator behavior detected in the network.',
      timestamp: '2 minutes ago',
      protocol: 'XRPL',
      impact: 'High',
      aiConfidence: 98.5
    },
    {
      id: 2,
      severity: 'warning',
      title: 'Payment Channel Pattern',
      message: 'Abnormal payment channel activity detected.',
      timestamp: '5 minutes ago',
      protocol: 'XRPL Bridge',
      impact: 'Medium',
      aiConfidence: 89.2
    }
  ],
  bnb: [
    {
      id: 1,
      severity: 'error',
      title: 'Smart Contract Alert',
      message: 'Suspicious interaction pattern in DeFi protocol.',
      timestamp: '2 minutes ago',
      protocol: 'PancakeSwap',
      impact: 'High',
      aiConfidence: 97.5
    },
    {
      id: 2,
      severity: 'warning',
      title: 'Large Transaction',
      message: 'Unusual token movement detected in liquidity pool.',
      timestamp: '5 minutes ago',
      protocol: 'Venus',
      impact: 'Medium',
      aiConfidence: 92.1
    }
  ],
  eth: [
    {
      id: 1,
      severity: 'error',
      title: 'Flash Loan Alert',
      message: 'Multiple flash loans detected in quick succession.',
      timestamp: '2 minutes ago',
      protocol: 'Uniswap V3',
      impact: 'High',
      aiConfidence: 99.1
    },
    {
      id: 2,
      severity: 'warning',
      title: 'Gas Price Spike',
      message: 'Significant increase in gas prices detected.',
      timestamp: '5 minutes ago',
      protocol: 'Ethereum Network',
      impact: 'Medium',
      aiConfidence: 94.5
    }
  ]
};

const AlertsPanel: React.FC<{ selectedChain?: string }> = ({ selectedChain = 'xrpl' }) => {
  const [alerts, setAlerts] = useState<AlertItem[]>(chainAlerts[selectedChain].slice(0, 3));
  // Update alerts when chain changes
  useEffect(() => {
    setAlerts(chainAlerts[selectedChain].slice(0, 3)); // Limit to 3 alerts on chain change
  }, [selectedChain]);


  // Simulate real-time alerts
  useEffect(() => {
    const interval = setInterval(() => {
      if (Math.random() > 0.7) { // 30% chance of new alert
        const newAlert: AlertItem = {
          id: Date.now(),
          severity: ['error', 'warning', 'info'][Math.floor(Math.random() * 3)] as any,
          title: `New ${selectedChain.toUpperCase()} Alert`,
          message: getChainSpecificMessage(selectedChain),
          timestamp: 'Just now',
          protocol: getChainSpecificProtocol(selectedChain),
          impact: ['High', 'Medium', 'Low'][Math.floor(Math.random() * 3)] as any,
          aiConfidence: Math.floor(Math.random() * 15) + 85
        };
        
        setAlerts(prev => [newAlert, ...prev.slice(0, 2)]);
      }
    }, 8000);

    return () => clearInterval(interval);
  }, [selectedChain]);

  const handleDismiss = (id: number) => {
    setAlerts(prev => prev.filter(alert => alert.id !== id));
  };

  const getSeverityIcon = (severity: string) => {
    switch (severity) {
      case 'error':
        return <ErrorIcon color="error" />;
      case 'warning':
        return <WarningIcon color="warning" />;
      default:
        return <InfoIcon color="info" />;
    }
  };

  return (
    <Paper sx={{ p: 3, height: '100%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6">
          Active Alerts - {selectedChain.toUpperCase()}
        </Typography>
        <Button variant="outlined" size="small">
          View All
        </Button>
      </Box>
      <Stack spacing={2}>
        {alerts.map((alert) => (
          <Alert 
            key={alert.id}
            severity={alert.severity}
            icon={getSeverityIcon(alert.severity)}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => handleDismiss(alert.id)}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ position: 'relative' }}
          >
            <Box sx={{ mr: 2 }}>
              <AlertTitle sx={{ mb: 0 }}>{alert.title}</AlertTitle>
              <Typography variant="body2" sx={{ mt: 1 }}>
                {alert.message}
              </Typography>
              <Box sx={{ mt: 1, display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                <Chip 
                  label={alert.protocol} 
                  size="small" 
                  color="primary" 
                  variant="outlined" 
                />
                <Chip 
                  label={`Impact: ${alert.impact}`} 
                  size="small" 
                  color={
                    alert.impact === 'High' ? 'error' : 
                    alert.impact === 'Medium' ? 'warning' : 'success'
                  }
                  variant="outlined" 
                />
                <Chip 
                  label={`AI Confidence: ${alert.aiConfidence}%`} 
                  size="small" 
                  color="info" 
                  variant="outlined" 
                />
                <Typography variant="caption" sx={{ ml: 'auto', color: 'text.secondary' }}>
                  {alert.timestamp}
                </Typography>
              </Box>
            </Box>
          </Alert>
        ))}
      </Stack>
    </Paper>
  );
};

// Helper functions for chain-specific data
const getChainSpecificMessage = (chain: string): string => {
  switch (chain) {
    case 'xrpl':
      return 'Unusual activity detected in XRPL network.';
    case 'bnb':
      return 'Suspicious pattern detected in BNB Chain protocol.';
    case 'eth':
      return 'Abnormal behavior detected in Ethereum network.';
    default:
      return 'Security alert detected.';
  }
};

const getChainSpecificProtocol = (chain: string): string => {
  switch (chain) {
    case 'xrpl':
      return ['XRPL Core', 'XRPL Bridge', 'Ripple Gateway'][Math.floor(Math.random() * 3)];
    case 'bnb':
      return ['PancakeSwap', 'Venus', 'BNB Bridge'][Math.floor(Math.random() * 3)];
    case 'eth':
      return ['Uniswap', 'Aave', 'Compound'][Math.floor(Math.random() * 3)];
    default:
      return 'Unknown Protocol';
  }
};

export default AlertsPanel;