// src/components/Dashboard/TransactionMonitor.tsx
import React, { useState, useEffect } from 'react';
import {
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  IconButton,
  Collapse,
  Box,
  Button,
} from '@mui/material';
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  Warning,
  CheckCircle,
} from '@mui/icons-material';

interface Transaction {
  id: string;
  hash: string;
  protocol: string;
  type: string;
  value: string;
  riskScore: number;
  timestamp: string;
  status: 'suspicious' | 'safe' | 'investigating';
  details: {
    from: string;
    to: string;
    gasUsed: string;
    method: string;
    aiAnalysis: string;
  };
}

const TransactionRow: React.FC<{ transaction: Transaction }> = ({ transaction }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell>{transaction.hash.substring(0, 8)}...</TableCell>
        <TableCell>{transaction.protocol}</TableCell>
        <TableCell>{transaction.type}</TableCell>
        <TableCell>{transaction.value}</TableCell>
        <TableCell>
          <Chip
            icon={transaction.riskScore > 70 ? <Warning /> : <CheckCircle />}
            label={`${transaction.riskScore}%`}
            color={transaction.riskScore > 70 ? 'error' : 'success'}
            size="small"
          />
        </TableCell>
        <TableCell>
          <Chip
            label={transaction.status}
            color={
              transaction.status === 'suspicious' ? 'error' :
              transaction.status === 'investigating' ? 'warning' : 'success'
            }
            size="small"
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Transaction Details
              </Typography>
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">From</TableCell>
                    <TableCell>{transaction.details.from}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">To</TableCell>
                    <TableCell>{transaction.details.to}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">Gas Used</TableCell>
                    <TableCell>{transaction.details.gasUsed}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">Method</TableCell>
                    <TableCell>{transaction.details.method}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">AI Analysis</TableCell>
                    <TableCell>{transaction.details.aiAnalysis}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const TransactionMonitor: React.FC = () => {
  const [transactions, setTransactions] = useState<Transaction[]>([]);

  useEffect(() => {
    // Simulate real-time transactions
    const interval = setInterval(() => {
      const newTransaction: Transaction = {
        id: Date.now().toString(),
        hash: `0x${Math.random().toString(16).substr(2, 40)}`,
        protocol: ['UniSwap V3', 'AAVE', 'PancakeSwap'][Math.floor(Math.random() * 3)],
        type: ['Swap', 'Liquidity', 'Flash Loan'][Math.floor(Math.random() * 3)],
        value: `$${(Math.random() * 1000000).toFixed(2)}`,
        riskScore: Math.floor(Math.random() * 100),
        timestamp: new Date().toISOString(),
        status: ['suspicious', 'safe', 'investigating'][Math.floor(Math.random() * 3)] as any,
        details: {
          from: `0x${Math.random().toString(16).substr(2, 40)}`,
          to: `0x${Math.random().toString(16).substr(2, 40)}`,
          gasUsed: `${Math.floor(Math.random() * 1000000)} wei`,
          method: 'transfer(address,uint256)',
          aiAnalysis: 'Transaction pattern matches normal protocol behavior.',
        },
      };

      setTransactions(prev => [newTransaction, ...prev].slice(0, 10));
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Paper sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <Typography variant="h6">Live Transaction Monitor</Typography>
        <Button variant="outlined" size="small">
          View All
        </Button>
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Hash</TableCell>
              <TableCell>Protocol</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Value</TableCell>
              <TableCell>Risk Score</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactions.map((transaction) => (
              <TransactionRow key={transaction.id} transaction={transaction} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default TransactionMonitor;