// src/components/Dashboard/index.tsx
import React, { useState, useCallback } from 'react';
import {
  Box,
  CssBaseline,
  Grid,
  Tab,
  Tabs,
  Paper,
  ThemeProvider,
  createTheme,
  useTheme,
  Typography,
  Select,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';
import Sidebar from './Sidebar';
import RiskScoreChart from './RiskScoreChart';
import AlertsPanel from './AlertsPanel';
import ProtocolHealth from './ProtocolHealth';
import AIMetrics from './AIMetrics';
import Header from './Header';
import NetworkMap from './NetworkMap';
import TransactionMonitor from './TransactionMonitor';
import AnalyticsPanel from './AnalyticsPanel';
import { Sensors as SensorsIcon } from '@mui/icons-material';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

// Add this type to handle any component with selectedChain prop
type ComponentWithChain = React.ComponentType<{ selectedChain?: string }>;

// Create a wrapper component to handle the type checking
const ChainAwareComponent: React.FC<{ 
  component: ComponentWithChain, 
  selectedChain: string 
}> = ({ component: Component, selectedChain }) => {
  return <Component selectedChain={selectedChain} />;
};

const TabPanel: React.FC<TabPanelProps> = ({ children, value, index }) => (
  <Box sx={{ display: value !== index ? 'none' : 'block' }}>
    {children}
  </Box>
);

const getAIInsights = (chain: string) => {
  const insights = {
    xrpl: {
      status: 'Optimal',
      activity: 'High',
      riskLevel: 'Low',
      lastUpdate: '2 minutes ago'
    },
    bnb: {
      status: 'Normal',
      activity: 'Very High',
      riskLevel: 'Medium',
      lastUpdate: '1 minute ago'
    },
    eth: {
      status: 'Good',
      activity: 'Moderate',
      riskLevel: 'Low',
      lastUpdate: '3 minutes ago'
    }
  };
  return insights[chain as keyof typeof insights] || insights.xrpl;
};


const CHAIN_OPTIONS = [
  { value: 'xrpl', label: 'XRPL' },
  { value: 'bnb', label: 'BNB Chain' },
  { value: 'eth', label: 'Ethereum' }
];

const Dashboard: React.FC = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [currentTab, setCurrentTab] = useState(0);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [selectedChain, setSelectedChain] = useState('xrpl');

  const theme = useTheme();
  
  const customTheme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: isDarkMode ? 'dark' : 'light',
          primary: {
            main: '#4a39d4',  // This replaces the default blue color
          },
          background: {
            default: isDarkMode ? '#121212' : '#f5f5f5',
            paper: isDarkMode ? '#1e1e1e' : '#ffffff',
          },
        },
      }),
    [isDarkMode]  // Remove theme.palette.primary from dependencies since we're not using it
  );

  const handleThemeChange = useCallback((isDark: boolean) => {
    setIsDarkMode(isDark);
  }, []);

  const handleToggleSidebar = useCallback(() => {
    setSidebarOpen(prev => !prev);
  }, []);

  const handleChainChange = (event: SelectChangeEvent) => {
    setSelectedChain(event.target.value);
  };

  return (
    <ThemeProvider theme={customTheme}>
      <Box sx={{ display: 'flex', height: '100vh', overflow: 'hidden' }}>
        <CssBaseline />
        <Header
          sidebarOpen={sidebarOpen}
          toggleSidebar={handleToggleSidebar}
          onThemeChange={handleThemeChange}
        />
        <Sidebar open={sidebarOpen} />
        
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            position: 'relative',
            height: '100vh',
            overflow: 'auto',
            backgroundColor: 'background.default',
            marginLeft: 0,
            transition: theme.transitions.create(['margin', 'width'], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
          }}
        >
          <Box sx={{ 
            mt: '64px',
            p: 2,
            height: 'calc(100vh - 64px)',
            overflow: 'auto'
          }}>

{/* Welcome Section */}
<Paper 
  elevation={0}
  sx={{ 
    mb: 2,
    p: 3,
    borderRadius: 1,
    border: '1px solid',
    borderColor: 'divider',
    bgcolor: 'background.paper', // Changed from background to bgcolor
  }}
>
  <Box sx={{ 
    display: 'flex', 
    flexDirection: 'column',
    gap: 3
  }}>
    {/* Header Section */}
    <Box sx={{ 
      display: 'flex', 
      alignItems: 'center', 
      justifyContent: 'space-between',
      gap: 2 
    }}>
      <Typography 
        variant="h4" 
        fontWeight="500"
        color="text.primary" // Ensures text color changes with theme
      >
        Welcome Admin
      </Typography>
      <Select
        size="small"
        value={selectedChain}
        onChange={handleChainChange}
        sx={{ 
          minWidth: 150,
          '& .MuiSelect-select': { py: 1 }
        }}
      >
        {CHAIN_OPTIONS.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </Box>

    {/* AI Insights Panel */}
    <Box sx={{ 
      display: 'flex',
      flexDirection: 'column',
      gap: 2,
      p: 2.5,
      bgcolor: theme.palette.mode === 'dark' 
        ? 'rgba(255, 255, 255, 0.03)' 
        : 'rgba(0, 0, 0, 0.02)',
      borderRadius: 2,
      border: '1px solid',
      borderColor: theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, 0.1)'
        : 'rgba(0, 0, 0, 0.05)',
    }}>
      {/* AI Insights Header */}
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        borderBottom: '1px solid',
        borderColor: theme.palette.mode === 'dark' 
          ? 'rgba(255, 255, 255, 0.1)' 
          : 'rgba(0, 0, 0, 0.05)',
        pb: 2
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
          <SensorsIcon color="primary" />
          <Box>
            <Typography 
              variant="h6" 
              color="text.primary"
            >
              AI Network Insights
            </Typography>
            <Typography 
              variant="body2" 
              color="text.secondary"
            >
              Monitoring {CHAIN_OPTIONS.find(opt => opt.value === selectedChain)?.label} security and performance
            </Typography>
          </Box>
        </Box>
        <Typography 
          variant="caption" 
          color="text.secondary"
          sx={{ 
            px: 1.5, 
            py: 0.5, 
            bgcolor: theme.palette.mode === 'dark' 
              ? 'rgba(255, 255, 255, 0.05)' 
              : 'rgba(0, 0, 0, 0.05)',
            borderRadius: 1,
          }}
        >
          Updated {getAIInsights(selectedChain).lastUpdate}
        </Typography>
      </Box>

      {/* Metrics Grid */}
      <Box sx={{ 
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)',
        gap: 3,
        mt: 1
      }}>
        {/* Network Status */}
        <Box>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            Network Status
          </Typography>
          <Typography variant="h6" color="text.primary" sx={{ fontWeight: 500 }}>
            {getAIInsights(selectedChain).status}
          </Typography>
        </Box>

        {/* Activity Level */}
        <Box>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            Activity Level
          </Typography>
          <Typography variant="h6" color="text.primary" sx={{ fontWeight: 500 }}>
            {getAIInsights(selectedChain).activity}
          </Typography>
        </Box>

        {/* Risk Level */}
        <Box>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            Risk Level
          </Typography>
          <Typography 
            variant="h6" 
            sx={{ 
              fontWeight: 500,
              color: getAIInsights(selectedChain).riskLevel === 'High' ? 'error.main' :
                     getAIInsights(selectedChain).riskLevel === 'Medium' ? 'warning.main' :
                     'success.main'
            }}
          >
            {getAIInsights(selectedChain).riskLevel}
          </Typography>
        </Box>

        {/* Active Nodes */}
        <Box>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            Active Nodes
          </Typography>
          <Typography variant="h6" color="text.primary" sx={{ fontWeight: 500 }}>
            {selectedChain === 'xrpl' ? '150+' : 
             selectedChain === 'bnb' ? '400+' : 
             '1000+'}
          </Typography>
        </Box>
      </Box>
    </Box>
  </Box>
</Paper>

            {/* Tabs Navigation */}
            <Paper 
              elevation={0}
              sx={{ 
                mb: 2,
                borderRadius: 1,
                border: '1px solid',
                borderColor: 'divider'
              }}
            >
              <Tabs
                value={currentTab}
                onChange={(_, newValue) => setCurrentTab(newValue)}
                variant="scrollable"
                scrollButtons="auto"
                sx={{ px: 2 }}
              >
                <Tab label="Overview" />
                <Tab label="Transactions" />
                <Tab label="Analytics" />
                <Tab label="Network" />
              </Tabs>
            </Paper>

            {/* Tab Content */}
            <TabPanel value={currentTab} index={0}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <ChainAwareComponent component={AIMetrics} selectedChain={selectedChain} />
                </Grid>
                <Grid item xs={12}>
                  <ChainAwareComponent component={RiskScoreChart} selectedChain={selectedChain} />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <ChainAwareComponent component={AlertsPanel} selectedChain={selectedChain} />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <ChainAwareComponent component={ProtocolHealth} selectedChain={selectedChain} />
                </Grid>
              </Grid>
            </TabPanel>

            <TabPanel value={currentTab} index={1}>
              <ChainAwareComponent component={TransactionMonitor} selectedChain={selectedChain} />
            </TabPanel>

            <TabPanel value={currentTab} index={2}>
              <ChainAwareComponent component={AnalyticsPanel} selectedChain={selectedChain} />
            </TabPanel>

            <TabPanel value={currentTab} index={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <ChainAwareComponent component={NetworkMap} selectedChain={selectedChain} />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <ChainAwareComponent component={AlertsPanel} selectedChain={selectedChain} />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <ChainAwareComponent component={ProtocolHealth} selectedChain={selectedChain} />
                </Grid>
              </Grid>
            </TabPanel>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default Dashboard;