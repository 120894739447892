// src/components/Dashboard/ProtocolHealth.tsx
import React, { useState, useEffect } from 'react';
import {
  Paper,
  Typography,
  Box,
  LinearProgress,
  Chip,
  IconButton,
  Tooltip,
  Divider,
} from '@mui/material';
import {
  Refresh as RefreshIcon,
  CheckCircle as CheckCircleIcon,
  Warning as WarningIcon,
  Error as ErrorIcon,
} from '@mui/icons-material';

interface ProtocolData {
  name: string;
  health: number;
  tvl: string;
  risk: number;
  status: 'Healthy' | 'Warning' | 'Critical';
  lastUpdated: string;
  aiRecommendation: string;
}

// Chain-specific protocol data
const chainProtocols: { [key: string]: ProtocolData[] } = {
  xrpl: [
    {
      name: 'XRPL Network',
      health: 98,
      tvl: '$45.8B',
      risk: 12,
      status: 'Healthy',
      lastUpdated: '1m ago',
      aiRecommendation: 'All validators performing well'
    },
    {
      name: 'XRPL Bridge',
      health: 92,
      tvl: '$2.5B',
      risk: 28,
      status: 'Warning',
      lastUpdated: '2m ago',
      aiRecommendation: 'Monitor bridge activity'
    },
    {
      name: 'Payment Channels',
      health: 96,
      tvl: '$1.2B',
      risk: 15,
      status: 'Healthy',
      lastUpdated: '3m ago',
      aiRecommendation: 'Normal operations'
    }
  ],
  bnb: [
    {
      name: 'PancakeSwap',
      health: 95,
      tvl: '$3.2B',
      risk: 18,
      status: 'Healthy',
      lastUpdated: '1m ago',
      aiRecommendation: 'Pools performing well'
    },
    {
      name: 'Venus',
      health: 89,
      tvl: '$1.5B',
      risk: 32,
      status: 'Warning',
      lastUpdated: '2m ago',
      aiRecommendation: 'Check lending rates'
    },
    {
      name: 'BNB Bridge',
      health: 94,
      tvl: '$850M',
      risk: 25,
      status: 'Healthy',
      lastUpdated: '3m ago',
      aiRecommendation: 'Normal operations'
    }
  ],
  eth: [
    {
      name: 'Uniswap V3',
      health: 97,
      tvl: '$5.8B',
      risk: 15,
      status: 'Healthy',
      lastUpdated: '1m ago',
      aiRecommendation: 'Optimal performance'
    },
    {
      name: 'Aave',
      health: 94,
      tvl: '$4.2B',
      risk: 22,
      status: 'Healthy',
      lastUpdated: '2m ago',
      aiRecommendation: 'Monitor rates'
    },
    {
      name: 'Compound',
      health: 91,
      tvl: '$2.1B',
      risk: 28,
      status: 'Warning',
      lastUpdated: '3m ago',
      aiRecommendation: 'Check collateral'
    }
  ]
};

const ProtocolHealth: React.FC<{ selectedChain?: string }> = ({ selectedChain = 'xrpl' }) => {
  const [protocols, setProtocols] = useState<ProtocolData[]>(chainProtocols[selectedChain]);
  const [isUpdating, setIsUpdating] = useState(false);

  // Update protocols when chain changes
  useEffect(() => {
    setProtocols(chainProtocols[selectedChain]);
  }, [selectedChain]);

  const getStatusColor = (status: string): 'success' | 'warning' | 'error' | 'default' => {
    switch (status) {
      case 'Healthy':
        return 'success';
      case 'Warning':
        return 'warning';
      case 'Critical':
        return 'error';
      default:
        return 'default';
    }
  };

  const getStatusIcon = (status: string) => {
    switch (status) {
      case 'Healthy':
        return <CheckCircleIcon fontSize="small" />;
      case 'Warning':
        return <WarningIcon fontSize="small" />;
      case 'Critical':
        return <ErrorIcon fontSize="small" />;
      default:
        return null;
    }
  };

  const updateProtocols = () => {
    setIsUpdating(true);
    setTimeout(() => {
      setProtocols(prev => prev.map(protocol => ({
        ...protocol,
        health: Math.max(85, Math.min(100, protocol.health + (Math.random() * 6 - 3))),
        risk: Math.max(0, Math.min(100, protocol.risk + (Math.random() * 10 - 5))),
        status: protocol.health > 95 ? 'Healthy' : protocol.health > 90 ? 'Warning' : 'Critical',
        lastUpdated: 'Just now'
      })));
      setIsUpdating(false);
    }, 1000);
  };

  useEffect(() => {
    const interval = setInterval(updateProtocols, 10000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Paper sx={{ p: 2.5, height: '100%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6">
          {selectedChain.toUpperCase()} Health Monitor
        </Typography>
        <IconButton onClick={updateProtocols} disabled={isUpdating} size="small">
          <RefreshIcon />
        </IconButton>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
        {protocols.map((protocol) => (
          <Box key={protocol.name}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                {getStatusIcon(protocol.status)}
                <Typography variant="subtitle2" fontWeight="medium">
                  {protocol.name}
                </Typography>
              </Box>
              <Tooltip title="Total Value Locked">
                <Chip
                  label={`TVL: ${protocol.tvl}`}
                  size="small"
                  variant="outlined"
                />
              </Tooltip>
            </Box>

            <Box sx={{ display: 'flex', gap: 2, mb: 1 }}>
              <Box sx={{ flex: 1 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
                  <Typography variant="caption" color="text.secondary">Health</Typography>
                  <Typography variant="caption">{protocol.health}%</Typography>
                </Box>
                <LinearProgress
                  variant="determinate"
                  value={protocol.health}
                  color={protocol.health > 95 ? 'success' : protocol.health > 90 ? 'warning' : 'error'}
                  sx={{ height: 4, borderRadius: 1 }}
                />
              </Box>

              <Box sx={{ flex: 1 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
                  <Typography variant="caption" color="text.secondary">Risk</Typography>
                  <Typography variant="caption">{protocol.risk}%</Typography>
                </Box>
                <LinearProgress
                  variant="determinate"
                  value={protocol.risk}
                  color={protocol.risk < 30 ? 'success' : protocol.risk < 70 ? 'warning' : 'error'}
                  sx={{ height: 4, borderRadius: 1 }}
                />
              </Box>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="caption" color="text.secondary">
                {protocol.aiRecommendation}
              </Typography>
              <Typography variant="caption" color="text.secondary">
                {protocol.lastUpdated}
              </Typography>
            </Box>
            
            {protocols.indexOf(protocol) !== protocols.length - 1 && (
              <Divider sx={{ mt: 1.5 }} />
            )}
          </Box>
        ))}
      </Box>
    </Paper>
  );
};

export default ProtocolHealth;