import React, { useState, useEffect } from 'react';
import {
  Paper,
  Typography,
  Box,
} from '@mui/material';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

const RiskScoreChart: React.FC = () => {
  const [data, setData] = useState(() => 
    Array.from({ length: 24 }, (_, i) => ({
      hour: `${i}:00`,
      risk: Math.floor(Math.random() * 60) + 20,
      anomalyScore: Math.floor(Math.random() * 40) + 10,
    }))
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setData(prev => {
        const newData = [...prev.slice(1)];
        const hour = prev[prev.length - 1].hour;
        const [h] = hour.split(':');
        const nextHour = `${(parseInt(h) + 1) % 24}:00`;
        newData.push({
          hour: nextHour,
          risk: Math.floor(Math.random() * 60) + 20,
          anomalyScore: Math.floor(Math.random() * 40) + 10,
        });
        return newData;
      });
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Paper sx={{ p: 3, height: 400 }}>
      <Typography variant="h6" gutterBottom>
        Real-time Risk Analysis
      </Typography>
      <Box sx={{ height: '90%' }}>
        <ResponsiveContainer>
          <LineChart
            data={data}
            margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis 
              dataKey="hour"
              padding={{ left: 30, right: 30 }}
            />
            <YAxis 
              domain={[0, 100]}
              ticks={[0, 20, 40, 60, 80, 100]}
            />
            <Tooltip />
            <Line
              name="Risk Score"
              type="monotone"
              dataKey="risk"
              stroke="#8884d8"
              strokeWidth={2}
            />
            <Line
              name="Anomaly Score"
              type="monotone"
              dataKey="anomalyScore"
              stroke="#82ca9d"
              strokeWidth={2}
            />
          </LineChart>
        </ResponsiveContainer>
      </Box>
    </Paper>
  );
};

export default RiskScoreChart;
