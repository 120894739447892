import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Box,
  IconButton,
  Badge,
  Menu,
  MenuItem,
  Avatar,
  Tooltip,
  Divider,
  Button,
  Switch,
  FormControlLabel,
} from '@mui/material';
import {
  Menu as MenuIcon,
  Notifications as NotificationsIcon,
  Settings as SettingsIcon,
  DarkMode as DarkModeIcon,
  AccountCircle,
  Search as SearchIcon,
  Help as HelpIcon,
} from '@mui/icons-material';

interface HeaderProps {
  sidebarOpen: boolean;
  toggleSidebar: () => void;
  onThemeChange?: (isDark: boolean) => void;
}

const Header: React.FC<HeaderProps> = ({ sidebarOpen, toggleSidebar, onThemeChange }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [notificationAnchor, setNotificationAnchor] = useState<null | HTMLElement>(null);
  const [isDarkMode, setIsDarkMode] = useState(false);

  // Mock notifications
  const notifications = [
    { id: 1, title: 'High Risk Alert', message: 'Suspicious activity detected in Protocol A', time: '2 min ago', type: 'alert' },
    { id: 2, title: 'System Update', message: 'New security features available', time: '1 hour ago', type: 'info' },
    { id: 3, title: 'Risk Score Update', message: 'Protocol B risk score increased by 20%', time: '3 hours ago', type: 'warning' },
  ];

  const handleNotificationClick = (event: React.MouseEvent<HTMLElement>) => {
    setNotificationAnchor(event.currentTarget);
  };

  const handleProfileClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setNotificationAnchor(null);
  };

  const handleThemeChange = () => {
    setIsDarkMode(!isDarkMode);
    if (onThemeChange) {
      onThemeChange(!isDarkMode);
    }
  };

  return (
    <AppBar 
      position="fixed" 
      sx={{ 
        zIndex: 2000,
        backgroundColor: 'background.paper',
        color: 'text.primary',
        boxShadow: 'none',
        borderBottom: '1px solid',
        borderColor: 'divider',
      }}
    >
      <Toolbar>
        <IconButton
          edge="start"
          color="primary"
          onClick={toggleSidebar}
          sx={{
            marginRight: 2,
            transition: 'transform 0.3s',
            transform: sidebarOpen ? 'rotate(0deg)' : 'rotate(180deg)',
          }}
        >
          <MenuIcon />
        </IconButton>

        {/* Logo */}
        <Box 
          component="img"
          src="/logo.png" // Make sure to add your logo file in the public folder
          alt="CryptoGuardian AI"
          sx={{
            height: 40,
            width: 'auto',
            mr: 2,
            cursor: 'pointer',
          }}
        />

        <Box sx={{ flexGrow: 1 }} />

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          {/* Global Search */}
          <IconButton color="primary">
            <SearchIcon />
          </IconButton>

          {/* Theme Toggle */}
          <Tooltip title="Toggle theme">
            <IconButton color="primary" onClick={handleThemeChange}>
              <DarkModeIcon />
            </IconButton>
          </Tooltip>

          {/* Help */}
          <Tooltip title="Help & Resources">
            <IconButton color="primary">
              <HelpIcon />
            </IconButton>
          </Tooltip>

          {/* Notifications */}
          <IconButton color="primary" onClick={handleNotificationClick}>
            <Badge badgeContent={notifications.length} color="error">
              <NotificationsIcon />
            </Badge>
          </IconButton>

          {/* Settings */}
          <IconButton color="primary">
            <SettingsIcon />
          </IconButton>

          {/* Profile */}
          <IconButton onClick={handleProfileClick}>
            <Avatar sx={{ width: 32, height: 32, bgcolor: 'primary.main' }}>A</Avatar>
          </IconButton>
        </Box>

        {/* Notifications Menu */}
        <Menu
          anchorEl={notificationAnchor}
          open={Boolean(notificationAnchor)}
          onClose={handleClose}
          PaperProps={{
            sx: { width: 360 }
          }}
        >
          <Box sx={{ p: 2 }}>
            <Box
              component="img"
              src="/logo.svg"
              alt="SEKURTY AI"
              sx={{ height: 24, width: 'auto', mb: 1 }}
            />
          </Box>
          <Divider />
          {notifications.map((notification) => (
            <MenuItem key={notification.id} onClick={handleClose}>
              <Box sx={{ py: 1 }}>
                <Box sx={{ fontWeight: 600, mb: 0.5 }}>{notification.title}</Box>
                <Box sx={{ color: 'text.secondary', fontSize: 14 }}>
                  {notification.message}
                </Box>
                <Box sx={{ color: 'text.secondary', fontSize: 12, mt: 0.5 }}>
                  {notification.time}
                </Box>
              </Box>
            </MenuItem>
          ))}
          <Divider />
          <Box sx={{ p: 1, display: 'flex', justifyContent: 'center' }}>
            <Button size="small" color="primary">View All Notifications</Button>
          </Box>
        </Menu>

        {/* Profile Menu */}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <Box sx={{ px: 2, py: 1 }}>
            <Box sx={{ fontWeight: 600 }}>Admin User</Box>
            <Box sx={{ color: 'text.secondary', fontSize: 14 }}>
              admin@sekurty.ai
            </Box>
          </Box>
          <Divider />
          <MenuItem onClick={handleClose}>Profile</MenuItem>
          <MenuItem onClick={handleClose}>Account Settings</MenuItem>
          <MenuItem onClick={handleClose}>Security Settings</MenuItem>
          <Divider />
          <MenuItem onClick={handleClose}>Sign Out</MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default Header;