// src/components/Dashboard/AnalyticsPanel.tsx
import React from 'react';
import {
  Paper,
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Tab,
  Tabs,
} from '@mui/material';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from 'recharts';

const threatData = [
  { name: 'Flash Loans', value: 35 },
  { name: 'Price Manipulation', value: 25 },
  { name: 'Reentrancy', value: 20 },
  { name: 'Access Control', value: 15 },
  { name: 'Others', value: 5 },
];

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8'];

const AnalyticsPanel: React.FC = () => {
  const [tab, setTab] = React.useState(0);

  const hourlyAttacks = Array.from({ length: 24 }, (_, i) => ({
    hour: `${i}:00`,
    attacks: Math.floor(Math.random() * 10),
    prevented: Math.floor(Math.random() * 8),
  }));

  return (
    <Paper sx={{ p: 3, height: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
        <Tabs value={tab} onChange={(_, newValue) => setTab(newValue)}>
          <Tab label="Threat Distribution" />
          <Tab label="Hourly Activity" />
          <Tab label="Network Stats" />
        </Tabs>
      </Box>

      <Box sx={{ display: tab === 0 ? 'block' : 'none' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Threat Distribution
                </Typography>
                <Box sx={{ height: 300 }}>
                  <ResponsiveContainer>
                    <PieChart>
                      <Pie
                        data={threatData}
                        dataKey="value"
                        nameKey="name"
                        cx="50%"
                        cy="50%"
                        outerRadius={80}
                        label
                      >
                        {threatData.map((_, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </Pie>
                      <Tooltip />
                    </PieChart>
                  </ResponsiveContainer>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Attack Prevention Stats
                </Typography>
                <Box sx={{ height: 300 }}>
                  <ResponsiveContainer>
                    <BarChart data={hourlyAttacks}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="hour" />
                      <YAxis />
                      <Tooltip />
                      <Bar dataKey="attacks" fill="#8884d8" name="Attacks Detected" />
                      <Bar dataKey="prevented" fill="#82ca9d" name="Attacks Prevented" />
                    </BarChart>
                  </ResponsiveContainer>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default AnalyticsPanel;